import { computed } from 'vue';

import { useLoading } from '@/modules/core';

import {
  useBookkeepingAccounts,
  useCreateBookkeepingAccount,
  useUpdateBookkeepingAccount,
} from '../../compositions/bookkeepingAccount';
import { ACCOUNT_TYPES } from '../../constants';

export const useSupplierBookkeepingAccounts = (variables) => {
  const creditAndDebitAndCodeOptions = computed(() => ({
    enabled: !!variables.value.customerId && !!variables.value.supplierId,
  }));
  const {
    bookkeepingAccounts: creditAndDebitAndCodeAccounts,
    refetch: refetchCreditAndDebitAndCode,
    loading: creditDebitAndCodeQueryLoading,
  } = useBookkeepingAccounts(variables, creditAndDebitAndCodeOptions);

  const creditAndDebitAndCodeLoading = useLoading(
    creditDebitAndCodeQueryLoading,
    variables,
    creditAndDebitAndCodeOptions
  );

  const getVatBookkeepingAccountsVariables = computed(() => ({
    bookkeeperId: variables.value.bookkeeperId,
    customerId: variables.value.customerId,
    type: ACCOUNT_TYPES.VAT,
  }));
  const vatOptions = computed(() => ({ enabled: !!getVatBookkeepingAccountsVariables.value.customerId }));
  const {
    bookkeepingAccounts: vatBookkeepingAccounts,
    refetch: refetchVat,
    loading: vatQueryLoading,
  } = useBookkeepingAccounts(getVatBookkeepingAccountsVariables, vatOptions);

  const vatLoading = useLoading(vatQueryLoading, variables, vatOptions);

  const { createBookkeepingAccount, onDone: onAccountCreated } = useCreateBookkeepingAccount();
  const { updateBookkeepingAccount, onDone: onAccountUpdated } = useUpdateBookkeepingAccount();

  onAccountCreated(() => {
    refetchVat();
    refetchCreditAndDebitAndCode();
  });
  onAccountUpdated(() => {
    refetchVat();
    refetchCreditAndDebitAndCode();
  });

  const debitBookkeepingAccount = computed(() =>
    creditAndDebitAndCodeAccounts.value.find((account) => account.type === ACCOUNT_TYPES.DEBIT)
  );
  const creditBookkeepingAccount = computed(() =>
    creditAndDebitAndCodeAccounts.value.find((account) => account.type === ACCOUNT_TYPES.CREDIT)
  );
  const codeBookkeepingAccount = computed(() =>
    creditAndDebitAndCodeAccounts.value.find((account) => account.type === ACCOUNT_TYPES.CODE)
  );
  const vatBookkeepingAccount = computed(() => vatBookkeepingAccounts.value[0]);

  const createOrUpdateBookkeepingAccount = (currentAccount, accountType, newAccountNumber, bookkeeperId) => {
    if (currentAccount) {
      return updateBookkeepingAccount({ id: currentAccount.id, data: { account: newAccountNumber } });
    } else {
      const createParams = {
        bookkeeperId,
        customerId: variables.value.customerId,
        type: accountType,
        account: newAccountNumber,
      };
      if (accountType !== ACCOUNT_TYPES.VAT) createParams.supplierId = variables.value.supplierId;
      return createBookkeepingAccount({ createParams });
    }
  };

  const loading = computed(() => creditAndDebitAndCodeLoading.value || vatLoading.value);

  return {
    loading,
    debitBookkeepingAccount,
    creditBookkeepingAccount,
    vatBookkeepingAccount,
    codeBookkeepingAccount,
    createOrUpdateBookkeepingAccount,
  };
};
