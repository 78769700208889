<template>
  <div>
    <el-dialog :visible="true" width="95%" custom-class="wizard-dialog rounded mt-5 mb-0" :show-close="false">
      <template #title>
        <div class="d-flex justify-content-between">
          <slot name="title" />
          <div>
            <el-dropdown trigger="click" type="bottom" placement="bottom-start" @command="handleAction($event)">
              <Button type="text" class="p-1 text-typography-primary secondary-hover-btn button">
                <KebabIcon />
              </Button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="ACTIONS.EXCLUDE_ENTITY">
                  <div class="d-flex align-items-center gap-2 text-danger">
                    <TrashCanIcon />
                    <p>
                      {{ $t('bookkeeping.bookkeepingManagement.unsentDataTransferBillings.table.actions.exclude') }}
                    </p>
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <Button :disabled="loading" type="icon" class="p-0" @click="$emit('close')">
              <CloseIcon />
            </Button>
          </div>
        </div>
      </template>
      <div v-loading="loading" class="d-flex pb-0 h-100">
        <div class="d-flex flex-column fields-section">
          <slot name="form" />
        </div>
        <div class="d-flex flex-column document-preview-body h-100 w-100">
          <slot name="document-preview" />
        </div>
      </div>
      <template #footer>
        <slot name="footer" />
      </template>
    </el-dialog>

    <ExcludeConfirmModal
      v-if="showExcludeConfirmModal"
      @confirm="handleExcludeConfirm"
      @cancel="handleExcludeCancel"
    ></ExcludeConfirmModal>
  </div>
</template>

<script>
import { ref } from 'vue';

import { Button } from '@/modules/core';
import { CloseIcon, KebabIcon, TrashCanIcon } from '@/assets/icons';

import ExcludeConfirmModal from '../../components/ExcludeConfirmModal.vue';

const ACTIONS = {
  EXCLUDE_ENTITY: 'excludeEntity',
};

export default {
  components: {
    Button,
    CloseIcon,
    KebabIcon,
    TrashCanIcon,
    ExcludeConfirmModal,
  },
  props: { loading: { type: Boolean, default: false }, entryId: { type: String, required: true } },
  setup(props, { emit }) {
    const showExcludeConfirmModal = ref(false);

    const handleAction = (command) => {
      switch (command) {
        case ACTIONS.EXCLUDE_ENTITY: {
          showExcludeConfirmModal.value = true;
          break;
        }
        default:
          break;
      }
    };

    const handleExcludeConfirm = () => {
      emit('entry-update', props.entryId);
      showExcludeConfirmModal.value = false;
    };
    const handleExcludeCancel = () => {
      showExcludeConfirmModal.value = false;
    };

    return { ACTIONS, showExcludeConfirmModal, handleAction, handleExcludeConfirm, handleExcludeCancel };
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

$dialogHeight: 95vh;
$dialogHeaderHeight: 92px;
$dialogBodyHeight: calc(#{$dialogHeight} - #{$dialogHeaderHeight} - 60px);

.fields-section {
  min-width: 285px;
}

:deep(.el-dialog.wizard-dialog) {
  height: $dialogHeight;

  .el-dialog__header {
    height: $dialogHeaderHeight;
    padding: 1.25rem;
  }
  .el-dialog__body {
    color: $typography-primary;
    height: $dialogBodyHeight;
    padding: 1.25rem;
  }
  .el-dialog__body,
  .el-dialog__footer {
    border-top: 1.5px solid $outline;
  }
}
</style>
