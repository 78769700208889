import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';

import { useNotification } from '@/modules/core';

export const billingEntriesBulkUpdate = () => {
  const { error } = useNotification();
  const {
    mutate: bulkUpdateBillingEntries,
    onDone: bulkUpdateBillingEntriesOnDone,
    onError,
  } = useMutation(BILLING_ENTRY_BULK_UPDATE_MUTATION);

  onError((err) => {
    console.error('bookkeeper - billingEntriesBulkUpdate', err);
    error();
  });

  return { bulkUpdateBillingEntries, bulkUpdateBillingEntriesOnDone };
};

const BILLING_ENTRY_BULK_UPDATE_MUTATION = gql`
  mutation billingEntriesBulkUpdate($data: BookkeepingBillingEntriesBulkUpdateInput) {
    billingEntriesBulkUpdate(data: $data)
  }
`;
