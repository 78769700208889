import { isNil, anyPass, isEmpty, indexBy, prop } from 'ramda';
import { gql } from '@apollo/client/core';
import { ref, computed, watch } from 'vue';
import { useMutation } from '@vue/apollo-composable';

import { useNotification } from '@/modules/core';

import { billingEntriesBulkUpdate } from './billingEntitiesBulkUpdate';

const isBillingEntryValidForExport = ({ bookkeepingEntry }) => {
  if (anyPass([isNil, isEmpty])(bookkeepingEntry.debits?.[0].account)) return false;
  if (isNil(bookkeepingEntry.debits?.[0]?.amount)) return false;
  if (anyPass([isNil, isEmpty])(bookkeepingEntry.credits?.[0]?.account)) return false;
  if (isNil(bookkeepingEntry.credits?.[0]?.amount)) return false;
  if (
    !isNil(bookkeepingEntry.debits?.[1]?.amount) &&
    bookkeepingEntry.debits?.[1]?.amount !== 0 &&
    anyPass([isNil, isEmpty])(bookkeepingEntry.debits[1]?.account)
  )
    return false;

  const debitsSum = bookkeepingEntry?.debits?.reduce((sum, { amount }) => sum + (amount || 0), 0) || 0;
  const creditsSum = bookkeepingEntry?.credits?.reduce((sum, { amount }) => sum + (amount || 0), 0) || 0;
  const roundedDebitsSum = debitsSum.toFixed(4);
  const roundedCreditsSum = creditsSum.toFixed(4);
  if (roundedDebitsSum !== roundedCreditsSum) return false;
  return true;
};

export const useExportWizardBillingEntries = (variables) => {
  const { error } = useNotification();
  const billingsById = indexBy(prop('id'), variables.value.billings);
  const sortBillingEntries = () =>
    [...variables.value.billingEntries].sort((a, b) => {
      const billingA = billingsById[a.billingId];
      const billingB = billingsById[b.billingId];
      if (!billingA || !billingB) return 0;
      return (
        (billingA?.supplier.name).localeCompare(billingB?.supplier.name) * 2 +
        (a.bookkeepingEntry?.referenceDate >= b.bookkeepingEntry?.referenceDate ? 1 : -1)
      );
    });

  const allBillingEntries = ref([]);

  watch(
    () => variables.value.billingEntries,
    () => (allBillingEntries.value = sortBillingEntries()),
    { immediate: true }
  );

  const {
    mutate: updateBillingEntry,
    loading: updatingBillingEntry,
    onDone,
    onError,
  } = useMutation(BILLING_ENTRY_UPDATE_MUTATION);

  onError((err) => {
    console.error('bookkeeper - useUpdateBillingEntry', err);
    error();
  });

  onDone((result) => {
    const updatedBillingEntry = result.data.billingEntryPatch;

    allBillingEntries.value = allBillingEntries.value.map((billingEntry) => {
      if (billingEntry.id === updatedBillingEntry.id) return updatedBillingEntry;
      return billingEntry;
    });
  });

  const ongoingBillingEntries = computed(() =>
    allBillingEntries.value.filter(({ billingId }) => variables.value.ongoingBillingIds.includes(billingId))
  );
  const validOngoingBillingEntries = computed(() =>
    ongoingBillingEntries.value.filter((billingEntry) => isBillingEntryValidForExport(billingEntry))
  );
  const invalidOngoingBillingEntries = computed(() =>
    ongoingBillingEntries.value.filter((billingEntry) => !isBillingEntryValidForExport(billingEntry))
  );

  const { bulkUpdateBillingEntries, bulkUpdateBillingEntriesOnDone } = billingEntriesBulkUpdate();

  return {
    ongoingBillingEntries,
    validOngoingBillingEntries,
    invalidOngoingBillingEntries,
    updateBillingEntry,
    updatingBillingEntry,
    bulkUpdateBillingEntries,
    bulkUpdateBillingEntriesOnDone,
  };
};

const BILLING_ENTRY_UPDATE_MUTATION = gql`
  mutation billingEntryPatch($id: ID!, $data: BookkeepingBillingEntryPatchInput) {
    billingEntryPatch(id: $id, data: $data) {
      id
      customerId
      supplierId
      bookkeeperId
      billingId
      bookkeepingEntry {
        code
        reference
        allocationNumber
        referenceDate
        valueDate
        details
        debits {
          account
          amount
        }
        credits {
          account
          amount
        }
      }
    }
  }
`;
