<template>
  <PopupLayout width="30rem" @close="$emit('close')">
    <template #title>
      <h2 class="title">{{ $t('bookkeeping.exportWizard.dialogs.export.title') }}</h2>
    </template>
    <template #body>
      <div>
        <p class="word-break">{{ $t('bookkeeping.exportWizard.dialogs.export.text') }}</p>
        <p class="word-break">
          {{ $t('bookkeeping.exportWizard.dialogs.export.text2', { no: invalidEntriesNumber }) }}
        </p>
      </div>
    </template>
    <template #buttons>
      <div>
        <Button type="secondary" @click="$emit('close')">
          {{ $t('bookkeeping.exportWizard.dialogs.export.cancel') }}
        </Button>
        <Button @click="$emit('export')">
          {{ $t('bookkeeping.exportWizard.dialogs.export.confirm') }}
        </Button>
      </div>
    </template>
  </PopupLayout>
</template>

<script>
import { Button } from '@/modules/core';

import PopupLayout from '../../components/PopupLayout';

export default {
  components: { PopupLayout, Button },
  props: { invalidEntriesNumber: { type: Number, required: true } },
};
</script>

<style scoped lang="scss">
.title {
  font-size: 1.25rem;
}

.word-break {
  word-break: normal;
}
</style>
