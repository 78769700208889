<template>
  <div>
    <WizardLayout
      :loading="loading"
      :entry-id="currentBillingEntry?.id"
      @close="$emit('close')"
      @entry-update="(entryId) => $emit('billing-entries-update', [entryId])"
    >
      <template #title>
        <div>
          <h2>
            {{ title }}
          </h2>
          <p>{{ subTitle }}</p>
        </div>
      </template>
      <template #form>
        <BookkeepingEntryForm
          ref="form"
          :billing-entry="currentBillingEntry"
          :billing="currentBilling"
          :bookkeeping-accounts-loading="bookkeepingAccountsLoading"
          :debit-bookkeeping-account="debitBookkeepingAccount"
          :vat-bookkeeping-account="vatBookkeepingAccount"
          :credit-bookkeeping-account="creditBookkeepingAccount"
          @update-billing-entry="handleBillingEntryUpdate"
          @save-default="handleSaveDefault"
        />
      </template>
      <template #document-preview>
        <DocumentPreview :document="currentDocument" />
      </template>
      <template #footer>
        <div class="d-flex justify-content-between">
          <div class="d-flex">
            <div v-if="showValidationError" class="d-flex align-items-center">
              <NoticeIcon class="text-danger" />
              <p class="text-danger" :class="$direction === 'rtl' ? 'me-2' : 'ms-2'">
                {{ $t('bookkeeping.exportWizard.validations.fillMissingInformation') }}
              </p>
            </div>
          </div>
          <div class="d-flex">
            <Button
              :class="$direction === 'rtl' ? 'ms-2' : 'me-2'"
              :disabled="loading || currentIndex === 0"
              type="secondary"
              @click="handlePreviousClick"
            >
              {{ $t('bookkeeping.exportWizard.buttons.previous') }}
            </Button>
            <div v-if="currentIndex + 1 < invalidBillingIds.length">
              <Button :disabled="loading" @click="handleNextClick">
                {{ $t('bookkeeping.exportWizard.buttons.next') }}
              </Button>
            </div>
            <div v-else>
              <Button :disabled="loading" @click="handleFinishAndExportClick">
                {{ $t('bookkeeping.exportWizard.buttons.finishAndExport') }}
              </Button>
            </div>
          </div>
        </div>
      </template>
    </WizardLayout>
    <ConfirmSkipOnMissingInformation
      v-if="openPopup === 'showConfirmSkipOnMissingInfo'"
      @close="openPopup = undefined"
      @skip="goToNext"
    />
    <ConfirmSaveDefaultAccount
      v-if="openPopup === 'showConfirmSaveDefaultAccount'"
      :type="accountToUpdateType"
      @confirm="handleSaveDefaultAccountConfirmed"
      @close="openPopup = undefined"
    />
    <PartialExportModal
      v-if="openPopup === 'showPartialExportModal'"
      :invalid-entries-number="invalidOngoingBillingEntries.length"
      @export="performExport"
      @close="openPopup = undefined"
    />
    <SkipSupplierModal
      v-if="openPopup === 'showSkipSupplierModal'"
      :count="numberOfSupplierEntriesToSkip"
      @skip="skipToNextSupplier"
      @next="goToNext"
      @close="openPopup = undefined"
    />
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue';
import { isNil } from 'ramda';

import { Button } from '@/modules/core';
import { NoticeIcon } from '@/assets/icons';

import WizardLayout from './WizardLayout';
import DocumentPreview from './DocumentPreview.vue';
import BookkeepingEntryForm from './BookkeepingEntryForm.vue';
import { useExportWizardBillingEntries } from '../composition/useExportWizardBillingEntries';
import { useSupplierBookkeepingAccounts } from '../composition/useSupplierBookkeepingAccounts';
import ConfirmSkipOnMissingInformation from './ConfirmSkipOnMissingInformation';
import ConfirmSaveDefaultAccount from './ConfirmSaveDefaultAccount';
import PartialExportModal from './PartialExportModal';
import SkipSupplierModal from './SkipSupplierModal';
import { ACCOUNT_TYPES } from '../../constants';

const sleep = async (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export default {
  name: 'ExportWizard',
  components: {
    WizardLayout,
    Button,
    DocumentPreview,
    BookkeepingEntryForm,
    NoticeIcon,
    ConfirmSkipOnMissingInformation,
    ConfirmSaveDefaultAccount,
    PartialExportModal,
    SkipSupplierModal,
  },
  props: {
    invalidBillingIds: { type: Array, required: true },
    validBillingIdsForExport: { type: Array, required: true },
    billings: { type: Array, required: true },
    allBillingEntries: { type: Array, required: true },
    customer: { type: Object, required: true },
    bookkeeperId: { type: String, required: true },
  },
  setup(props, { emit }) {
    const currentIndex = ref(0);
    const loading = ref(false);
    const currentDocument = ref(null);

    const {
      ongoingBillingEntries,
      validOngoingBillingEntries,
      invalidOngoingBillingEntries,
      updateBillingEntry,
      updatingBillingEntry,
      bulkUpdateBillingEntries,
      bulkUpdateBillingEntriesOnDone,
    } = useExportWizardBillingEntries(
      computed(() => ({
        billingEntries: props.allBillingEntries,
        ongoingBillingIds: props.invalidBillingIds,
        billings: props.billings,
      }))
    );

    bulkUpdateBillingEntriesOnDone(() => emit('billing-entries-update'));

    const currentBillingEntry = computed(() => ongoingBillingEntries.value[currentIndex.value]);
    const currentBilling = computed(() => props.billings?.find(({ id }) => id === currentBillingEntry.value.billingId));
    const currentSupplierId = computed(() => currentBilling.value.supplier.id);

    watch(
      () => ongoingBillingEntries.value.length,
      (entriesLength) => {
        if (entriesLength <= 0) {
          emit('close');
        }

        if (entriesLength === currentIndex.value) {
          currentIndex.value = entriesLength - 1;
        }
      }
    );

    watch(
      () => currentBilling.value.id,
      (newId) => {
        if (!isNil(newId)) {
          currentDocument.value = currentBilling.value?.eventReferences?.find(
            (eventReference) => eventReference.regulatory
          ).document;
        }
      },
      { immediate: true }
    );

    const {
      debitBookkeepingAccount,
      creditBookkeepingAccount,
      vatBookkeepingAccount,
      createOrUpdateBookkeepingAccount,
      loading: bookkeepingAccountsLoading,
    } = useSupplierBookkeepingAccounts(
      computed(() => ({
        bookkeeperId: props.bookkeeperId,
        customerId: props.customer.id,
        supplierId: currentSupplierId.value,
      }))
    );

    return {
      ongoingBillingEntries,
      validOngoingBillingEntries,
      invalidOngoingBillingEntries,
      loading,
      currentIndex,
      currentBillingEntry,
      currentBilling,
      currentSupplierId,
      currentDocument,
      bookkeepingAccountsLoading,
      debitBookkeepingAccount,
      creditBookkeepingAccount,
      vatBookkeepingAccount,
      updateBillingEntry,
      bulkUpdateBillingEntries,
      showValidationError: ref(false),
      openPopup: ref(undefined),
      createOrUpdateBookkeepingAccount,
      updatingBillingEntry,
      shouldAllowToSkipSupplier: ref(false),
      accountToUpdateType: ref(null),
      accountToUpdateNumber: ref(null),
    };
  },
  computed: {
    title() {
      return this.$t('bookkeeping.exportWizard.title', {
        index: this.currentIndex + 1,
        total: this.invalidBillingIds.length,
      });
    },
    subTitle() {
      return this.customer.name;
    },
    numberOfSupplierEntriesToSkip() {
      const remainingEntries = this.ongoingBillingEntries.slice(this.currentIndex + 1);
      const remainingSupplierEntries = remainingEntries.filter((billingEntry) => {
        const billing = this.billings.find((bill) => bill.id === billingEntry.billingId);
        return billing.supplier.id === this.currentSupplierId;
      });
      return remainingSupplierEntries.length;
    },
  },
  methods: {
    clearValidations() {
      this.$refs.form.$refs.form.clearValidate();
      this.showValidationError = false;
    },
    async validateForm() {
      try {
        await this.$refs.form.$refs.form.validate();
        this.showValidationError = false;
      } catch {
        this.showValidationError = true;
      }
    },
    async goToNext() {
      this.openPopup = undefined;
      this.shouldAllowToSkipSupplier = false;
      this.$refs.form.$refs.form.resetFields();
      this.currentIndex += 1;
      await this.$nextTick();
      await this.$nextTick();
      this.clearValidations();
    },
    async handleNextClick() {
      try {
        await this.$refs.form.$refs.form.validate();
        if (this.shouldAllowToSkipSupplier && this.numberOfSupplierEntriesToSkip) {
          this.openPopup = 'showSkipSupplierModal';
        } else {
          await this.goToNext();
        }
      } catch {
        this.showValidationError = true;
        this.openPopup = 'showConfirmSkipOnMissingInfo';
      }
    },
    async handlePreviousClick() {
      this.shouldAllowToSkipSupplier = false;
      this.currentIndex -= 1;
      await this.$nextTick();
      await this.$nextTick();
      await this.validateForm();
    },
    handleBillingEntryUpdate(updateData) {
      this.updateBillingEntry({ id: this.currentBillingEntry.id, data: updateData });
    },
    getCurrentAccount(type) {
      switch (type) {
        case ACCOUNT_TYPES.DEBIT:
          return this.debitBookkeepingAccount;
        case ACCOUNT_TYPES.VAT:
          return this.vatBookkeepingAccount;
        case ACCOUNT_TYPES.CREDIT:
          return this.creditBookkeepingAccount;
        default:
          return;
      }
    },
    async handleSaveDefaultAccountConfirmed({ override }) {
      this.loading = true;
      this.openPopup = undefined;
      const currentAccount = this.getCurrentAccount(this.accountToUpdateType);
      await this.createOrUpdateBookkeepingAccount(
        currentAccount,
        this.accountToUpdateType,
        this.accountToUpdateNumber,
        this.bookkeeperId
      );
      await this.bulkUpdateBillingEntries({
        data: {
          customerId: this.customer.id,
          ...(this.accountToUpdateType !== ACCOUNT_TYPES.VAT && { supplierId: this.currentSupplierId }),
          accountType: this.accountToUpdateType,
          accountNumber: this.accountToUpdateNumber,
          override,
        },
      });
      this.accountToUpdateType = null;
      this.accountToUpdateNumber = null;
      this.loading = false;
    },
    handleSaveDefault({ type, number }) {
      this.accountToUpdateType = type;
      this.accountToUpdateNumber = number;
      this.openPopup = 'showConfirmSaveDefaultAccount';
    },
    performExport() {
      this.loading = true;
      this.openPopup = undefined;
      const validBillingIds = this.validOngoingBillingEntries.map(({ billingId }) => billingId);
      const billingIdsForExport = [...this.validBillingIdsForExport, ...validBillingIds];
      if (billingIdsForExport.length > 0) {
        this.$emit('export-entries', billingIdsForExport);
      } else {
        this.loading = false;
      }
    },
    async handleFinishAndExportClick() {
      while (this.updatingBillingEntry) {
        await sleep(100);
      }
      if (this.invalidOngoingBillingEntries.length) {
        this.openPopup = 'showPartialExportModal';
      } else {
        this.performExport();
      }
    },
    async skipToNextSupplier() {
      this.openPopup = undefined;
      const skipToIndex = this.ongoingBillingEntries.findIndex((billingEntry, index) => {
        const billing = this.billings.find((bill) => bill.id === billingEntry.billingId);
        return index > this.currentIndex && billing.supplier.id !== this.currentSupplierId;
      });
      if (skipToIndex !== -1) this.currentIndex = skipToIndex;
      else this.currentIndex = this.ongoingBillingEntries.length - 1;
      await this.$nextTick();
      this.clearValidations();
    },
  },
};
</script>
